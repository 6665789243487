import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { ITelaOperacao } from '../models/ITelaOperacao';
import { ITelaComunicacao } from '../models/ITelaComunicacao';
import { ITelaEstrutura } from '../models/ITelaEstrutura';
import { IOrdenacao } from '../models/Consulta/IOrdenacao';

export interface ICrudBase {
  estrutura: ITelaEstrutura;
  comunicacao: ITelaComunicacao;
  operacao: ITelaOperacao;
  exibirModal: boolean;
}
export interface IRetornoCrudBase{
  crudBase: ICrudBase;
  exibirModal(telaGenerica: ITelaOperacao): void;
  sincronizaTelaCrud(objeto: ITelaComunicacao): void;
  preencherEstrutura(): ITelaEstrutura;
  preencherCamposOrdenacao(): IOrdenacao[];
}
export function useCrudBase():IRetornoCrudBase {
  const crudBase = reactive({
    estrutura: {} as ITelaEstrutura,
    comunicacao: {} as ITelaComunicacao,
    operacao: {} as ITelaOperacao,
    exibirModal: false,
  });

  function preencherEstrutura(): ITelaEstrutura {
    const route = useRoute();
    crudBase.estrutura.titulo = String(route.meta.titulo);
    crudBase.estrutura.identificadorRecurso = String(route.meta.identificadorRecurso);
    crudBase.estrutura.identificadorPermissao = String(route.meta.identificadorPermissao);
    crudBase.estrutura.botaoPrincipalAcao = 'Novo cadastro';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do registro';
    crudBase.estrutura.linkBaseConhecimento = '';
    crudBase.estrutura.linkVideoGuia = '';
    crudBase.estrutura.ordenacaoPadraoGrade = [];
    crudBase.estrutura.apresentarAcaoVisualizarGrade = true;
    crudBase.estrutura.apresentarAcaoImprimirGrade = true;
    crudBase.estrutura.apresentarAcaoDuplicarGrade = false;
    crudBase.estrutura.apresentarAcaoCriarFilhoGrade = false;
    crudBase.estrutura.apresentarAcaoExcluirGrade = true;
    crudBase.estrutura.textoAcaoCriarFilhoGrade = 'Criar filho';
    return crudBase.estrutura;
  }

  function preencherCamposOrdenacao(): IOrdenacao[] {
    const camposOrdenacao = [] as IOrdenacao[];
    crudBase.estrutura.colunasGrade.forEach((c) => {
      if (c.ordering) {
        const campo = {} as IOrdenacao;
        if (c.key !== undefined) {
          campo.identificador = c.key;
        }
        if (c.title !== undefined) {
          campo.descricao = c.title;
        }
        campo.ordem = 'ASC';
        camposOrdenacao.push(campo);
      }
    });
    return camposOrdenacao;
  }

  function exibirModal(objeto: ITelaOperacao) {
    crudBase.operacao = objeto;
    crudBase.exibirModal = true;
  }

  function sincronizaTelaCrud(objeto: ITelaComunicacao) {
    crudBase.comunicacao = objeto;
  }

  return {
    crudBase,
    exibirModal,
    sincronizaTelaCrud,
    preencherEstrutura,
    preencherCamposOrdenacao,
  };
}
